//import bootstrap from 'bootstrap';

//import logo from './logo.svg';

//import Header from './Header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container className="py-3">
      <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">Paul Sjoberg</span>
          </a>
        </div>

        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">About me</h1>
          <p className="fs-5 text-muted">
            I'm a serial entrepreneur, Founder/CEO of <a href="https://leverageitnow.com/" target="_blank" rel="noreferrer">Leverage Information Technologies (LIT)</a>, owner of <a href="https://truenorthrealty.com" target="_blank" rel="noreferrer">True North Realty</a>, and second in command to my wife of a family with four children. I have lived in 3 countries, 7 states, and have a passion for the diversity the world provides. I'm an extreme pragmatist who has always taken a unique approach to life.  My goal each and every day is to wake up a better person than the day before.
          </p>
        </div>
      </header>
      <main>
        <Row className="justify-content-md-center text-center">
          <Col md={4} xs={12} className="mb-2">
            <div className="card mb-4 rounded-3 shadow-sm h-100">
              <div className="card-header py-3 bg-lit">
                <h4 className="my-0 fw-normal">LIT</h4>
              </div>
              <div className="card-body">
                <h2 className="card-title"><small className="text-muted fw-light">Top-notch</small> <span className="tc-lit">IT services</span></h2>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Leverage Information Technologies</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                </ul>
                <a href="https://leverageitnow.com/" target="_blank" className="w-100 btn btn-lg btn-lit" rel="noreferrer">Find out more</a>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} className="mb-2">
            <div className="card mb-4 rounded-3 shadow-sm h-100">
              <div className="card-header py-3 bg-truenorth">
                <h4 className="my-0 fw-normal">True North Realty</h4>
              </div>
              <div className="card-body">
                <h2 className="card-title"><small className="text-muted fw-light">Success is</small> <span className="tc-truenorth">client satisfaction</span></h2>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Licensed brokerage in VA</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                </ul>
                <a href="https://truenorthrealty.com" target="_blank" className="w-100 btn btn-lg btn-truenorth" rel="noreferrer">Get started</a>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} className="mb-2">
            <div className="card mb-4 rounded-3 shadow-sm h-100">
              <div className="card-header py-3 bg-3at">
                <h4 className="my-0 fw-normal">Three Action Thursday</h4>
              </div>
              <div className="card-body align-middle">
                <h2 className="card-title"><small className="text-muted fw-light">A growth mindset</small> <span className="tc-3at">takes action</span></h2>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Insightful content</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                </ul>
                <a href="https://threeactionthursday.com" target="_blank" className="w-100 btn btn-lg btn-3at" rel="noreferrer">Subscribe</a>
              </div>
            </div>
          </Col>
        </Row>
      </main>
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <Row>
          <Col md xs={12}>
            <small className="d-block mb-3 text-muted">© 2022</small>
          </Col>
          <Col md xs={6}>
            <h5>Useful Links</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://leverageitnow.com" target="_blank" rel="noreferrer">Leverage Information Technology</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://truenorthrealty.com" target="_blank" rel="noreferrer">True North Realty</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://threeactionthursday.com" target="_blank" rel="noreferrer">Three Action Thursday</a></li>
            </ul>
          </Col>
        </Row>
      </footer>
    </Container>
  );
}

export default App;
